const icons = {
  contacts: {
    address: require("./contacts/address.svg").default,
    email: require("./contacts/email.svg").default,
    phone: require("./contacts/phone.svg").default,
  },
  payment: {
    googlepay: require("./payment/googlpay.svg").default,
    liqpay: require("./payment/liqpay.svg").default,
    mastercard: require("./payment/mastercart.svg").default,
    moonopay: require("./payment/no.svg").default,
    iphoepay: require("./payment/iphoepay.svg").default,
    visa: require("./payment/visa.svg").default,
  },
  social: {
    balance: require("./social/belance.svg").default,
    facebook: require("./social/feysbook.svg").default,
    instagram: require("./social/instagram.svg").default,
    pinterest: require("./social/pinterest.svg").default,
    tiktok: require("./social/tiktok.svg").default,
    twitter: require("./social/twiter.svg").default,
    youtube: require("./social/youtube.svg").default,
  },
};

export default icons;
