import { useTranslation } from "react-i18next";
import icon from "../img/footer/index";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { sendEmail } from "../function";

const Modal = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    setLoading(true);
    sendEmail(e, t, setLoading);
    toggleModal();
  };
  useEffect(() => {
    const checkHash = () => {
      if (location.hash === "#modal") {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    };
    checkHash();
    window.addEventListener("hashchange", checkHash);
    return () => {
      window.removeEventListener("hashchange", checkHash);
    };
  }, [location.hash, setIsOpen]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      window.history.pushState(null, null, "#modal");
    } else {
      window.history.pushState(null, null, " ");
    }
  };

  return isOpen ? (
    <>
      <div
        className="fixed inset-0 flex items-center justify-center bg-gray-900/70 z-50"
        onClick={toggleModal}
      >
        <div
          className="relative bg-white p-8 rounded-lg shadow-lg"
          onClick={(e) => e.stopPropagation()}
        >
          <button
            onClick={toggleModal}
            className="absolute flex items-center justify-center flex-col -top-20 right-0 text-gray-500 duration-300 hover:scale-105 hover:text-gray-700 bg-accent-500 rounded-full px-6 py-4"
          >
            <span
              className={`block h-0.5 w-8 bg-black rounded-sm transition-transform duration-300 ease-out 
                   rotate-45 translate-y-0.5
                }`}
            ></span>
            <span
              className={`block h-0.5 w-8 bg-black rounded-sm transition-transform duration-300 ease-out 
                  -rotate-45 
                `}
            ></span>
          </button>

          <div className="text-center">
            <div className="text-yellow-500 mt-4">
              <img
                className="m-auto"
                src={icon.contacts.email}
                alt="email"
                width="40px"
              />
            </div>

            <h2 className="absolute -top-[25px] uppercase bg-accent-500 p-3 inset-x-0 text-lg font-bold text-additional-500 mx-6 rounded-xl">
              {t("modal.title")}
            </h2>
            <p className="text-gray-500 mt-2 mb-6">{t("modal.description")}</p>

            <form onSubmit={handleSubmit}>
              <input
                type="text"
                required
                name="user_name"
                placeholder={t("modal.namePlaceholder")}
                className="block w-full border text-center border-gray-300 rounded-lg p-2.5 mb-4 text-gray-700 focus:outline-none focus:border-accent-500"
                autoCapitalize="name"
              />
              <input
                type="tel"
                required
                name="user_phone"
                pattern="^[+]?[0-9]{9,12}$"
                placeholder={t("modal.phonePlaceholder")}
                className="block w-full border text-center border-gray-300 rounded-lg p-2.5 mb-6 text-gray-700 focus:outline-none focus:border-accent-500"
                autoCapitalize="tel"
              />
              <button
                type="submit"
                className="bg-yellow-500 w-full text-white text-lg py-2 rounded-lg hover:bg-accent-500 transition duration-200"
              >
                {loading ? t("form.loadingText") : t("modal.submitButton")}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default Modal;
