export const questionsAnswersUk = {
  title: "Запитання та відповіді",
  items1: {
    text: "Яка вага Tiny House Alkinoy?",
    description:
      "Tiny House Alkinoy виготовляється з легких, але міцних матеріалів, що полегшує транспортування, не знижуючи міцності.",
  },
  items2: {
    text: "Яка вартість Tiny House Alkinoy?",
    description:
      "Вартість Tiny House Alkinoy залежить від варіантів налаштувань. Зв'яжіться з нами для отримання детальної пропозиції.",
  },
  items3: {
    text: "Чи експортується Tiny House Alkinoy за межі України?",
    description:
      "Так, Tiny House Alkinoy може бути експортований на міжнародному рівні. Ми займаємося усіма необхідними логістичними питаннями.",
  },
  items4: {
    text: "Які засоби кліматичного контролю передбачені в Tiny House Alkinoy?",
    description:
      "Tiny House Alkinoy обладнаний системами опалення та кондиціювання для забезпечення комфорту в будь-яких погодних умовах.",
  },
  items5: {
    text: "Як ці будинки підключаються до водопостачання, каналізації та електрики?",
    description:
      "Tiny House Alkinoy спроектований для легкого підключення до систем водопостачання, каналізації та електрики.",
  },
  items6: {
    text: "Чи підійде маленький будиночок для сім’ї з дітьми?",
    description:
      "Tiny House Alkinoy має гнучкі варіанти простору та може бути налаштований під потреби сімей з дітьми.",
  },
  items7: {
    text: "Чи можу я спробувати маленький будиночок у користуванні?",
    description:
      "Ми пропонуємо пробні проживання, щоб ви могли випробувати Tiny House Alkinoy перед покупкою.",
  },
  items8: {
    text: "Чи можуть ваші маленькі будиночки бути більшими?",
    description:
      "Так, Tiny House Alkinoy пропонує модульні варіанти, які дозволяють розширити простір відповідно до ваших потреб.",
  },
};

export const blogUk = {
  title: "Блог",
  listTitle: "Всі блоги",
  time: "хв. читання",
  blog1: {
    title: "Що таке модульний будинок?",
    text: "Модульне житло - це своєрідний конструктор, у якому ціла будівля збирається з окремих частин - модулів. Модульний будинок передусім відрізняється від стандартної житлової конструкції особливостями будівництва. Секції виробляють на заводах, а збирають безпосередньо на території замовника. Блоки можуть бути як окремою стіною, так і одразу збірною кімнатою. Деталі виробляються за інноваційними технологіями та з дотриманням державних стандартів. Для будівництва модульних будинків використовуються термопрофілі, дерево, піноматеріали та різні утеплювачі.",
    data: "12.09.2024",
  },
  blog2: {
    title: "У чому переваги модульного будинку та які є недоліки?",
    text: "Житло модульного типу — не просто вимушений захід. У всьому світі такі будинки стали справжнім трендом, передусім завдяки багатьом перевагам. Багато людей скептично ставляться до модульного будівництва, вважаючи збірні споруди тимчасовими та непридатними для постійного проживання. Але це міф. Насправді, існує щонайменше 15 причин звернути увагу на модульний будинок.",
    data: "13.09.2024",
  },
  blog3: {
    title: "Швидке будівництво",
    text: "Перший аргумент – можливість отримати повноцінне житло вже за 1-1,5 місяця, тоді як за зведення капітальної споруди можуть піти роки. По завершенню збірки модульний будинок транспортується та монтується на ділянці за 1-2 дні з урахуванням підключення необхідних комунікацій, після чого в ньому можна одразу проживати.",
    data: "14.09.2024",
  },
  blog4: {
    title: "Зручність зведення",
    text: " Часто модульні будинки вибирають не лише через можливість швидко побудувати житло. Не менш важливою перевагою таких конструкцій є легке транспортування модулів.",
    data: "15.09.2024",
  },
  blog5: {
    title: "Мобільність",
    text: "Модульний будинок транспортується в готовому вигляді та швидко монтується на потрібній ділянці. Власники модульних конструкцій можуть переїздити разом із будинком. А деякі будинки можна розібрати по модулях для зручного перевезення. Це означає, що власник може за бажанням поставити свій дім в іншому місці. За необхідності змінюється район та навіть населений пункт.",
    data: "16.09.2024",
  },
  blog6: {
    title: "Можливість заощадження",
    text: "Якщо потрібно швидко забезпечити житлом, цей варіант дуже економічний. А коли додати до цього ще й заощадження людських ресурсів під час будівництва та економію витрат часу, переваги модульного житла стають ще більш очевидними.",
    data: "17.09.2024",
  },
  blog7: {
    title: "Екологічність та безпека",
    text: "Матеріали для виготовлення модулів відповідають міжнародним стандартам безпеки. Зазвичай, за основу береться метал та дерево. Як утеплювач використовуються мінеральна та базальтова вата. Внутрішнє оздоблення – це ОСБ-плити, вологостійкий гіпсокартон, склоколонито тощо. В такому будинку зможуть з комфортом проживати маленькі діти, літні люди, тварини, алергіки.",
    data: "18.09.2024",
  },
  blog8: {
    title: "Енергоефективність ",
    text: "Це одна з найперших вимог до сучасного житла, якій модульний будинок відповідає цілком і повністю. Для цього використовуються якісні енергоефективні вікна, утеплювачі, системи та технології, що дозволяють заощаджувати на витратах на опалення та електроенергію.",
    data: "19.09.2024",
  },
  blog9: {
    title: "Великий вибір місць розташування",
    text: "Конструкції доволі компактні та не мають обмежень в умовах будівництва, як-от специфічні особливості ландшафту. Коли є потреба побудувати на піщаних чи глинистих територіях, то класичний фундамент необов’язковий. Альтернативою стає повітряна подушка, зверху якої — залізобетонна плита. Саме тому відкривається широкий вибір майданчиків для будівництва.",
    data: "20.09.2024",
  },
  blog10: {
    title: "Легке перепланування",
    text: "Якщо ви є власником модульного житла, потреби у затяжних, брудних будівельних роботах з перепланування не буде. За допомогою спеціалістів ви легко та швидко зможете додати або прибрати модулі за декілька днів, змінивши площу та кількість кімнат. Швидшого ремонту годі й бажати! Особливості модулів дають змогу створювати житло чи нежитлові приміщення під конкретні запити. Це дуже зручно з огляду на раціональний підхід до будівництва.",
    data: "21.09.2024",
  },
  blog11: {
    title: "Необов’язковий ремонт",
    text: "Модульні конструкції мають такий зовнішній та внутрішній вигляд, що можна заїхати одразу. У випадку зі звичайним приватним будинком, як правило, це не вийде — їхати після появи «коробки».",
    data: "21.09.2024",
  },
  blog12: {
    title: "Інженерні комунікації в модульному будинку",
    text: "Готові модульні конструкції мають розведення для систем опалення, електрики, водовідведення та вентиляції. Виробник забезпечує розведення всередині будинку, а до нього треба підвести всі комунікації самостійно.",
    data: "21.09.2024",
  },
  blog13: {
    title: "Щорічне будівництво",
    text: "Коли мова йде про виготовлення модульних будинків, пора року та погодні умови не важливі. Навіть всі виробничі процеси відбуваються в опалювальному приміщенні, що дозволяє не припиняти створення конструкцій і забезпечувати людей житлом щорічно.",
    data: "21.09.2024",
  },
  blog14: {
    title: "Турбота про навколишнє середовище",
    text: "Абсолютна більшість матеріалів, з яких створюються модульні будинки, підлягають повторному переробленню. Такий підхід дозволяє зменшити навантаження на навколишнє середовище, але при цьому ніяк не впливає на довговічність житла — модульні конструкції служитимуть 50 і більше років.",
    data: "21.09.2024",
  },
  blog15: {
    title: "Дозвіл на будівництво та паспорт модульного будинку",
    text: "Будівництво звичайного приватного будинку передбачає обов’язкове оформлення документів. У випадку з модульною конструкцією такої потреби немає. З іншого боку, коли ви вирішите надовго залишитися на певному місці і встановити будинок на фундаменті, цілком можете оформити документи — і тоді офіційно матимете нерухоме майно. Земельна ділянка, на якій знаходиться модульний житловий будинок, має бути в оренді або власності. Дозволи при цьому не потрібні навіть за умови проведення комунікацій на ділянці, якщо ви маєте проєктні документи, які підтверджують: цей модульний будинок — капітальна споруда. Тоді треба звернутися до архітектурного бюро місцевої влади. Після цього будинок отримає паспорт будівництва, інвентарну адресу і можливість прописатися. Це убезпечить себе від обмежених умов і з усіма правилами страхування нерухомого майна. Після цього вже можна переходити до установки споруди.",
    data: "21.09.2024",
  },
  blog16: {
    title: "Різноманітне використання",
    text: "Виготовлення модульних будинків далеко не завжди пов’язане з використанням як житла для окремої родини. Це також може бути готелем, хостелом, кемпінгом, гуртожитком, кафе, базою відпочинку.",
    data: "21.09.2024",
  },
  blog17: {
    title: "Модульний будинок в зимовий період",
    text: "Модульні будинки придатні для проживання в холодний період, і це ще одна їхня беззаперечна перевага. На період зими модульні будинки приєднують до автономного опалення або до центральних мереж. У більшості випадків для будівництва використовуються індукційні обігрівачі або котел. З їхнім основним газовим нагріванням власники таких будинків не відчують ніякої різниці з класичним приватним будинком, де традиційно проводять опалення. Також можна підключити до основної електроенергії та забезпечити затишок всередині. Крім того, сучасні модулі створюються з найвищими теплоізоляційними характеристиками. Встановлення інфрачервоного теплення, вбудованих автоматик із системою «Розумний будинок» — усе це можна інтегрувати в модульні будинки.",
    data: "21.09.2024",
  },
  blog18: {
    title: "Висновки",
    text: "Тож чим є модульні будинки: тимчасовим рішенням у безвихідних ситуаціях або затишним родинним гніздечком? Насправді все залежить від якості, цілей використання, матеріалів, місця розміщення. Сучасні технології дозволяють за обмежений час створити функціональне й енергоефективне житло, яке є доволі комфортним. Вибір модульного будинку – це вибір сучасних технологій, комфорту та екології. Незалежно від того, чи потрібен він вам для постійного проживання чи заміського відпочинку, такий будинок надає безліч можливостей для створення ідеального житла, яке відповідає всім вашим потребам та бажанням.",
    data: "21.09.2024",
  },
};

export const housesUk = {
  nordicC: {
    header: {
      text: "Ідеально підходить для основного місця проживання, розміщення для гостей, будинку для відпочинку, домашнього офісу/студії, прибутку від оренди або мережі готелів.",
      title: "Компактне проживання",
    },
    housesFilling: {
      inside: {
        list: {
          1: {
            title: "Чистове внутрішнє оздоблення",
            description: "",
          },
          2: {
            title: "Сантехніка",
            description:
              "Душова кабіна зі скляною перегородкою або акрилова ванна на вибір, душова стійка, підвісна тумба з раковиною, унітаз, змішувачі.",
          },
          3: {
            title: "Вентиляція",
            description:
              "Примусова система вентиляції у с.у. та кухні, припливно-витяжні клапани в кімнатах та вітальні.",
          },
          4: {
            title: "Бойлер",
            description: "Об'ємом 100 літрів",
          },
          5: {
            title: "Прихована електропроводка у підсистемі",
            description:
              "Вимикачі, розетки, світильники зі світлодіодними лампами",
          },
          6: {
            title: "Міжкімнатні двері",
            description: "",
          },
        },
      },
      outside: {
        list: {
          1: {
            title: "Чистове внутрішнє оздоблення",
            description: "",
          },
          2: {
            title: "Сантехніка",
            description:
              "Душова кабіна зі скляною перегородкою або акрилова ванна на вибір, душова стійка, підвісна тумба з раковиною, унітаз, змішувачі.",
          },
          3: {
            title: "Вентиляція",
            description:
              "Примусова система вентиляції у с.у. та кухні, припливно-витяжні клапани в кімнатах та вітальні.",
          },
          4: {
            title: "Бойлер",
            description: "Об'ємом 100 літрів",
          },
          5: {
            title: "Прихована електропроводка у підсистемі",
            description:
              "Вимикачі, розетки, світильники зі світлодіодними лампами",
          },
          6: {
            title: "Міжкімнатні двері",
            description: "",
          },
        },
      },
      notIncludedPrice: {
        list: {
          1: {
            title: "доставка модуля(ів) до вашої ділянки;",
          },
        },
      },

      options: {
        list: {
          1: {
            text: "Це готовий будинок, оснащений найнеобхіднішим для життя: оздоблення, світильники та розетки, кухня з необхідною технікою, меблі, обладнана ванна кімната.",
          },
          2: {
            text: "Одна з головних переваг нової моделі - мобільність. Якщо ви захочете змінити локацію - будинок легко перевезти на нове місце.",
          },
        },
      },
      important: {
        list: {
          1: {
            text: "Одна з головних переваг нової моделі - мобільність. Якщо ви захочете змінити локацію - будинок легко перевезти на нове місце.",
          },
        },
      },
    },
    question: {
      list: {
        1: {
          title: "Чи підходить такий будинок для проживання цілий рік?",
          description:
            "Так, у модульному будинку ви можете проживати постійно, він зовсім не відрізняється від звичайного будинку!",
        },
        2: {
          title: "Який фундамент потрібен під такий будинок?",
          description:
            "Для модульного будинку зазвичай використовується полегшений фундамент, однак вибір залежить від типу ґрунту.",
        },
        3: {
          title: "Яка товщина утеплювача потрібна?",
          description:
            "Товщина утеплювача має бути не меншою за 100 мм для забезпечення комфортного проживання в будь-яку пору року.",
        },
        4: {
          title: "Чи можу я замовити будинок за власним дизайном?",
          description:
            "Так, ви можете обрати власний дизайн та планування для вашого будинку, враховуючи індивідуальні побажання.",
        },
      },
    },
  },
  nordicK: {
    header: {
      text: "Чудовий варіант як для дачі так і для комерційного використання.",
      title: "Компактне проживання2",
    },
    housesFilling: {
      inside: {
        list: {
          1: {
            title: "Чистове внутрішнє оздоблення",
            description: "",
          },
          2: {
            title: "Сантехніка",
            description:
              "Душова кабіна зі скляною перегородкою або акрилова ванна на вибір, душова стійка, підвісна тумба з раковиною, унітаз, змішувачі.",
          },
          3: {
            title: "Вентиляція",
            description:
              "Примусова система вентиляції у с.у. та кухні, припливно-витяжні клапани в кімнатах та вітальні.",
          },
          4: {
            title: "Бойлер",
            description: "Об'ємом 100 літрів",
          },
          5: {
            title: "Прихована електропроводка у підсистемі",
            description:
              "Вимикачі, розетки, світильники зі світлодіодними лампами",
          },
          6: {
            title: "Міжкімнатні двері",
            description: "",
          },
        },
      },
      outside: {
        list: {
          1: {
            title: "Чистове внутрішнє оздоблення",
            description: "",
          },
          2: {
            title: "Сантехніка",
            description:
              "Душова кабіна зі скляною перегородкою або акрилова ванна на вибір, душова стійка, підвісна тумба з раковиною, унітаз, змішувачі.",
          },
          3: {
            title: "Вентиляція",
            description:
              "Примусова система вентиляції у с.у. та кухні, припливно-витяжні клапани в кімнатах та вітальні.",
          },
          4: {
            title: "Бойлер",
            description: "Об'ємом 100 літрів",
          },
          5: {
            title: "Прихована електропроводка у підсистемі",
            description:
              "Вимикачі, розетки, світильники зі світлодіодними лампами",
          },
          6: {
            title: "Міжкімнатні двері",
            description: "",
          },
        },
      },
      notIncludedPrice: {
        list: {
          1: {
            title: "доставка модуля(ів) до вашої ділянки;",
          },
        },
      },

      options: {
        1: {
          text: "Це готовий будинок, оснащений найнеобхіднішим для життя: оздоблення, світильники та розетки, кухня з необхідною технікою, меблі, обладнана ванна кімната.",
        },
        2: {
          text: "Одна з головних переваг нової моделі - мобільність. Якщо ви захочете змінити локацію - будинок легко перевезти на нове місце.",
        },
      },
      important: {
        1: {
          text: "Одна з головних переваг нової моделі - мобільність. Якщо ви захочете змінити локацію - будинок легко перевезти на нове місце.",
        },
      },
    },
    question: {
      list: {
        1: {
          title: "Чи підходить такий будинок для проживання цілий рік?",
          description:
            "Так, у модульному будинку ви можете проживати постійно, він зовсім не відрізняється від звичайного будинку!",
        },
        2: {
          title: "Який фундамент потрібен під такий будинок?",
          description:
            "Для модульного будинку зазвичай використовується полегшений фундамент, однак вибір залежить від типу ґрунту.",
        },
        3: {
          title: "Яка товщина утеплювача потрібна?",
          description:
            "Товщина утеплювача має бути не меншою за 100 мм для забезпечення комфортного проживання в будь-яку пору року.",
        },
        4: {
          title: "Чи можу я замовити будинок за власним дизайном?",
          description:
            "Так, ви можете обрати власний дизайн та планування для вашого будинку, враховуючи індивідуальні побажання.",
        },
      },
    },
  },
  nordicM: {
    header: {
      text: "Чудовий варіант як для дачі так і для комерційного використання.",
      title: "Компактне проживання3",
    },
    housesFilling: {
      inside: {
        list: {
          1: {
            title: "Чистове внутрішнє оздоблення",
            description: "",
          },
          2: {
            title: "Сантехніка",
            description:
              "Душова кабіна зі скляною перегородкою або акрилова ванна на вибір, душова стійка, підвісна тумба з раковиною, унітаз, змішувачі.",
          },
          3: {
            title: "Вентиляція",
            description:
              "Примусова система вентиляції у с.у. та кухні, припливно-витяжні клапани в кімнатах та вітальні.",
          },
          4: {
            title: "Бойлер",
            description: "Об'ємом 100 літрів",
          },
          5: {
            title: "Прихована електропроводка у підсистемі",
            description:
              "Вимикачі, розетки, світильники зі світлодіодними лампами",
          },
          6: {
            title: "Міжкімнатні двері",
            description: "",
          },
        },
      },
      outside: {
        list: {
          1: {
            title: "Чистове внутрішнє оздоблення",
            description: "",
          },
          2: {
            title: "Сантехніка",
            description:
              "Душова кабіна зі скляною перегородкою або акрилова ванна на вибір, душова стійка, підвісна тумба з раковиною, унітаз, змішувачі.",
          },
          3: {
            title: "Вентиляція",
            description:
              "Примусова система вентиляції у с.у. та кухні, припливно-витяжні клапани в кімнатах та вітальні.",
          },
          4: {
            title: "Бойлер",
            description: "Об'ємом 100 літрів",
          },
          5: {
            title: "Прихована електропроводка у підсистемі",
            description:
              "Вимикачі, розетки, світильники зі світлодіодними лампами",
          },
          6: {
            title: "Міжкімнатні двері",
            description: "",
          },
        },
      },
      notIncludedPrice: {
        list: {
          1: {
            title: "доставка модуля(ів) до вашої ділянки;",
          },
        },
      },
      options: {
        1: {
          text: "Це готовий будинок, оснащений найнеобхіднішим для життя: оздоблення, світильники та розетки, кухня з необхідною технікою, меблі, обладнана ванна кімната.",
        },
        2: {
          text: "Одна з головних переваг нової моделі - мобільність. Якщо ви захочете змінити локацію - будинок легко перевезти на нове місце.",
        },
      },
      important: {
        1: {
          text: "Одна з головних переваг нової моделі - мобільність. Якщо ви захочете змінити локацію - будинок легко перевезти на нове місце.",
        },
      },
    },
    question: {
      list: {
        1: {
          title: "Чи підходить такий будинок для проживання цілий рік?",
          description:
            "Так, у модульному будинку ви можете проживати постійно, він зовсім не відрізняється від звичайного будинку!",
        },
        2: {
          title: "Який фундамент потрібен під такий будинок?",
          description:
            "Для модульного будинку зазвичай використовується полегшений фундамент, однак вибір залежить від типу ґрунту.",
        },
        3: {
          title: "Яка товщина утеплювача потрібна?",
          description:
            "Товщина утеплювача має бути не меншою за 100 мм для забезпечення комфортного проживання в будь-яку пору року.",
        },
        4: {
          title: "Чи можу я замовити будинок за власним дизайном?",
          description:
            "Так, ви можете обрати власний дизайн та планування для вашого будинку, враховуючи індивідуальні побажання.",
        },
      },
    },
  },
  nordicY: {
    header: {
      text: "Ідеально підходить для основного місця проживання, розміщення для гостей, будинку для відпочинку, домашнього офісу/студії, прибутку від оренди або мережі готелів.",
      title: "Компактне проживання4",
    },
    housesFilling: {
      inside: {
        list: {
          1: {
            title: "Чистове внутрішнє оздоблення",
            description: "",
          },
          2: {
            title: "Сантехніка",
            description:
              "Душова кабіна зі скляною перегородкою або акрилова ванна на вибір, душова стійка, підвісна тумба з раковиною, унітаз, змішувачі.",
          },
          3: {
            title: "Вентиляція",
            description:
              "Примусова система вентиляції у с.у. та кухні, припливно-витяжні клапани в кімнатах та вітальні.",
          },
          4: {
            title: "Бойлер",
            description: "Об'ємом 100 літрів",
          },
          5: {
            title: "Прихована електропроводка у підсистемі",
            description:
              "Вимикачі, розетки, світильники зі світлодіодними лампами",
          },
          6: {
            title: "Міжкімнатні двері",
            description: "",
          },
        },
      },
      outside: {
        list: {
          1: {
            title: "Чистове внутрішнє оздоблення",
            description: "",
          },
          2: {
            title: "Сантехніка",
            description:
              "Душова кабіна зі скляною перегородкою або акрилова ванна на вибір, душова стійка, підвісна тумба з раковиною, унітаз, змішувачі.",
          },
          3: {
            title: "Вентиляція",
            description:
              "Примусова система вентиляції у с.у. та кухні, припливно-витяжні клапани в кімнатах та вітальні.",
          },
          4: {
            title: "Бойлер",
            description: "Об'ємом 100 літрів",
          },
          5: {
            title: "Прихована електропроводка у підсистемі",
            description:
              "Вимикачі, розетки, світильники зі світлодіодними лампами",
          },
          6: {
            title: "Міжкімнатні двері",
            description: "",
          },
        },
      },
      notIncludedPrice: {
        list: {
          1: {
            title: "доставка модуля(ів) до вашої ділянки;",
          },
        },
      },
      options: {
        1: {
          text: "Це готовий будинок, оснащений найнеобхіднішим для життя: оздоблення, світильники та розетки, кухня з необхідною технікою, меблі, обладнана ванна кімната.",
        },
        2: {
          text: "Одна з головних переваг нової моделі - мобільність. Якщо ви захочете змінити локацію - будинок легко перевезти на нове місце.",
        },
      },
      important: {
        1: {
          text: "Одна з головних переваг нової моделі - мобільність. Якщо ви захочете змінити локацію - будинок легко перевезти на нове місце.",
        },
      },
    },
    question: {
      list: {
        1: {
          title: "Чи підходить такий будинок для проживання цілий рік?",
          description:
            "Так, у модульному будинку ви можете проживати постійно, він зовсім не відрізняється від звичайного будинку!",
        },
        2: {
          title: "Який фундамент потрібен під такий будинок?",
          description:
            "Для модульного будинку зазвичай використовується полегшений фундамент, однак вибір залежить від типу ґрунту.",
        },
        3: {
          title: "Яка товщина утеплювача потрібна?",
          description:
            "Товщина утеплювача має бути не меншою за 100 мм для забезпечення комфортного проживання в будь-яку пору року.",
        },
        4: {
          title: "Чи можу я замовити будинок за власним дизайном?",
          description:
            "Так, ви можете обрати власний дизайн та планування для вашого будинку, враховуючи індивідуальні побажання.",
        },
      },
    },
  },
  gonta: {
    header: {
      text: "Ідеально підходить для основного місця проживання, розміщення для гостей, будинку для відпочинку, домашнього офісу/студії, прибутку від оренди або мережі готелів.",
      title: "Компактне проживання5",
    },
    housesFilling: {
      inside: {
        list: {
          1: {
            title: "Чистове внутрішнє оздоблення",
            description: "",
          },
          2: {
            title: "Сантехніка",
            description:
              "Душова кабіна зі скляною перегородкою або акрилова ванна на вибір, душова стійка, підвісна тумба з раковиною, унітаз, змішувачі.",
          },
          3: {
            title: "Вентиляція",
            description:
              "Примусова система вентиляції у с.у. та кухні, припливно-витяжні клапани в кімнатах та вітальні.",
          },
          4: {
            title: "Бойлер",
            description: "Об'ємом 100 літрів",
          },
          5: {
            title: "Прихована електропроводка у підсистемі",
            description:
              "Вимикачі, розетки, світильники зі світлодіодними лампами",
          },
          6: {
            title: "Міжкімнатні двері",
            description: "",
          },
        },
      },
      outside: {
        list: {
          1: {
            title: "Чистове внутрішнє оздоблення",
            description: "",
          },
          2: {
            title: "Сантехніка",
            description:
              "Душова кабіна зі скляною перегородкою або акрилова ванна на вибір, душова стійка, підвісна тумба з раковиною, унітаз, змішувачі.",
          },
          3: {
            title: "Вентиляція",
            description:
              "Примусова система вентиляції у с.у. та кухні, припливно-витяжні клапани в кімнатах та вітальні.",
          },
          4: {
            title: "Бойлер",
            description: "Об'ємом 100 літрів",
          },
          5: {
            title: "Прихована електропроводка у підсистемі",
            description:
              "Вимикачі, розетки, світильники зі світлодіодними лампами",
          },
          6: {
            title: "Міжкімнатні двері",
            description: "",
          },
        },
      },
      notIncludedPrice: {
        list: {
          1: {
            title: "доставка модуля(ів) до вашої ділянки;",
          },
        },
      },
      options: {
        1: {
          text: "Це готовий будинок, оснащений найнеобхіднішим для життя: оздоблення, світильники та розетки, кухня з необхідною технікою, меблі, обладнана ванна кімната.",
        },
        2: {
          text: "Одна з головних переваг нової моделі - мобільність. Якщо ви захочете змінити локацію - будинок легко перевезти на нове місце.",
        },
      },
      important: {
        1: {
          text: "Одна з головних переваг нової моделі - мобільність. Якщо ви захочете змінити локацію - будинок легко перевезти на нове місце.",
        },
      },
    },
    question: {
      list: {
        1: {
          title: "Чи підходить такий будинок для проживання цілий рік?",
          description:
            "Так, у модульному будинку ви можете проживати постійно, він зовсім не відрізняється від звичайного будинку!",
        },
        2: {
          title: "Який фундамент потрібен під такий будинок?",
          description:
            "Для модульного будинку зазвичай використовується полегшений фундамент, однак вибір залежить від типу ґрунту.",
        },
        3: {
          title: "Яка товщина утеплювача потрібна?",
          description:
            "Товщина утеплювача має бути не меншою за 100 мм для забезпечення комфортного проживання в будь-яку пору року.",
        },
        4: {
          title: "Чи можу я замовити будинок за власним дизайном?",
          description:
            "Так, ви можете обрати власний дизайн та планування для вашого будинку, враховуючи індивідуальні побажання.",
        },
      },
    },
  },
};

export const houseUk = {
  timeUnits: {
    weeks: "тижні",
    months: "місяці",
  },
  housesFilling: {
    title: "Наповнення модульного будинку",
    includedPrice: "Включено у вартість",
    delivery: "Доставка",
    inside: "Усередині",
    outside: "Зовні",
    notIncludedPrice: "Не входить у вартість",
    features: "Особливості",
    options: "Опції та додаткові можливості",
    important: "Важлива інформація",
  },
  housePlan: {
    title: "План майбутнього будинку",
    characteristics: "характеристика",
    bedroom: "Спальня",
    kitchenLiving: "Кухня-вітальня",
    bathroom: "Кухня-вітальня",
  },
  gallery: {
    title: "Галерея",
    facade: "Фасад",
    interior: "Інтерєр",
  },
  houseSimilar: {
    title: "Cхожі Проєкти",
    button: "Докладніше",
  },
  question: "Популярні питання",
  price: "Ціна",
  buildingArea: "Площа забудови",
  usefulArea: "Корисна площа",
  design: "Дизайн",
  production: "Виробництво",
  transportation: "Транспортування",
  installation: "Встановлення",
  from: "від",
};
