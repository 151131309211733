import { lazy, Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Modal from "./component/Modal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Home = lazy(() => import("./page/Home"));

const Gallery = lazy(() => import("./page/Gallery"));
const Prices = lazy(() => import("./page/Prices"));
const Blog = lazy(() => import("./page/Blog"));
const BlogDetail = lazy(() => import("./page/BlogDetail"));
const ModularHouses = lazy(() => import("./page/ModularHouses"));
function App() {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const storedLanguage = localStorage.getItem("i18nextLng") || "uk";
    const pathLanguage = location.pathname.split("/")[1];
    if (!pathLanguage) {
      navigate(`/${storedLanguage}${location.pathname}`, { replace: true });
    } else {
      i18n.changeLanguage(pathLanguage);
    }
  }, [i18n, location, navigate]);
  return (
    <Suspense>
      <Modal />
      <Routes>
        <Route
          path="/:lng"
          index
          element={
            <Suspense fallback={<>...</>}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/:lng/modular-houses/:idHouse"
          element={
            <Suspense fallback={<>...</>}>
              <ModularHouses />
            </Suspense>
          }
        />
        <Route
          path="/:lng/gallery"
          element={
            <Suspense fallback={<>...</>}>
              <Gallery />
            </Suspense>
          }
        />
        <Route
          path="/:lng/prices"
          element={
            <Suspense fallback={<>...</>}>
              <Prices />
            </Suspense>
          }
        />
        <Route
          path="/:lng/blog"
          element={
            <Suspense fallback={<>...</>}>
              <Blog />
            </Suspense>
          }
        />

        <Route
          path="/:lng/blog/:id"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <BlogDetail />
            </Suspense>
          }
        />
      </Routes>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </Suspense>
  );
}

export default App;
