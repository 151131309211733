export const questionsAnswersEn = {
  title: "Questions and answers",
  items1: {
    text: "What is the weight of Tiny House Alkinoy?",
    description:
      "The Tiny House Alkinoy is built using lightweight yet durable materials, making it easy to transport without compromising on strength.",
  },
  items2: {
    text: "What is the cost of Tiny House Alkinoy?",
    description:
      "The price of Tiny House Alkinoy varies based on customization options. Contact us for a detailed quote.",
  },
  items3: {
    text: "Is Tiny House Alkinoy exported outside of Ukraine?",
    description:
      "Yes, Tiny House Alkinoy can be shipped internationally. We handle all necessary logistics for exporting.",
  },
  items4: {
    text: "What climate control measures are available in Tiny House Alkinoy?",
    description:
      "The Tiny House Alkinoy is equipped with heating and air conditioning systems to ensure comfort in all weather conditions.",
  },
  items5: {
    text: "How are these houses connected to water, sewage, and electricity?",
    description:
      "Tiny House Alkinoy is designed for seamless connection to utilities, including water supply, sewage systems, and electricity.",
  },
  items6: {
    text: "Is the tiny house suitable for a family with children?",
    description:
      "The Tiny House Alkinoy has flexible space options and can be customized to suit the needs of families with children.",
  },
  items7: {
    text: "Can I try using the tiny house?",
    description:
      "We offer trial stays so you can experience the Tiny House Alkinoy before making a purchase decision.",
  },
  items8: {
    text: "Can your tiny houses be made larger?",
    description:
      "Yes, Tiny House Alkinoy offers modular options that allow for expanding the space to suit your needs.",
  },
};

export const blogEn = {
  title: "Blog",
  listTitle: "All blogs",
  time: "min read",
  blog1: {
    title: "What is a Modular House?",
    text: "Modular housing is a kind of construction set where an entire building is assembled from separate parts - modules. A modular house differs from a standard residential structure primarily in the construction process. The sections are produced in factories and assembled directly at the customer's site. Blocks can be individual walls or pre-assembled rooms. The components are manufactured using innovative technologies and in compliance with state standards. Modular homes are built using thermal profiles, wood, foam materials, and various insulators.",
    data: "12.09.2024",
  },
  blog2: {
    title: "What are the Advantages and Disadvantages of a Modular House?",
    text: "Modular housing is not just a forced measure. These homes have become a true trend worldwide, mainly due to their many advantages. Many people are skeptical of modular construction, considering such buildings temporary and unsuitable for permanent living. But that's a myth. In fact, there are at least 15 reasons to pay attention to modular homes.",
    data: "13.09.2024",
  },
  blog3: {
    title: "Fast Construction",
    text: "The first argument is the possibility of having a fully functional home in 1-1.5 months, while it may take years to build a permanent structure. Once assembled, a modular house can be transported and installed on-site within 1-2 days, including utility connections, after which it is immediately ready for occupancy.",
    data: "14.09.2024",
  },
  blog4: {
    title: "Ease of Construction",
    text: "Modular homes are often chosen not only for their fast construction. Another significant advantage of these structures is the ease of transporting the modules.",
    data: "15.09.2024",
  },
  blog5: {
    title: "Mobility",
    text: "A modular house is transported in a ready-to-install state and quickly set up at the desired location. Owners of modular homes can move with their house. Some homes can even be disassembled into modules for easier transportation. This means the owner can relocate the house to another location if desired, changing the area or even the city.",
    data: "16.09.2024",
  },
  blog6: {
    title: "Cost-Efficiency",
    text: "If you need to provide housing quickly, this option is very economical. When you add the savings on human resources during construction and time, the benefits of modular housing become even more evident.",
    data: "17.09.2024",
  },
  blog7: {
    title: "Eco-Friendliness and Safety",
    text: "The materials used for modular homes meet international safety standards. Typically, metal and wood are the main components, and insulation includes mineral and basalt wool. The interior finish consists of OSB boards, moisture-resistant drywall, glass wool, etc. Such homes are safe for small children, the elderly, pets, and those with allergies.",
    data: "18.09.2024",
  },
  blog8: {
    title: "Energy Efficiency",
    text: "This is one of the foremost requirements of modern housing, and modular homes fully meet it. High-quality energy-efficient windows, insulation, systems, and technologies are used, allowing for savings on heating and electricity costs.",
    data: "19.09.2024",
  },
  blog9: {
    title: "Wide Selection of Locations",
    text: "The structures are quite compact and not limited by specific landscape conditions for construction. For instance, traditional foundations are unnecessary when building on sandy or clay soils. Instead, an air cushion with a reinforced concrete slab on top is used. This opens up many location options for construction.",
    data: "20.09.2024",
  },
  blog10: {
    title: "Easy Remodeling",
    text: "If you own a modular home, there is no need for lengthy, messy construction work for remodeling. With the help of specialists, you can easily and quickly add or remove modules in just a few days, adjusting the space and number of rooms. No faster renovation exists! The modular design allows you to create housing or non-residential spaces tailored to specific needs, making construction more rational.",
    data: "21.09.2024",
  },
  blog11: {
    title: "No Mandatory Renovation",
    text: "Modular structures are designed so that you can move in immediately. In the case of a regular private house, this is usually not possible until after the 'shell' is completed.",
    data: "21.09.2024",
  },
  blog12: {
    title: "Engineering Communications in a Modular House",
    text: "Ready-made modular structures come with heating, electricity, drainage, and ventilation systems pre-installed. The manufacturer provides the internal systems, but the connections to external utilities must be handled separately.",
    data: "21.09.2024",
  },
  blog13: {
    title: "Year-Round Construction",
    text: "When it comes to manufacturing modular homes, the time of year and weather conditions are not important. All production processes take place indoors, allowing continuous construction and ensuring year-round housing availability.",
    data: "21.09.2024",
  },
  blog14: {
    title: "Environmental Care",
    text: "The majority of materials used to create modular homes are recyclable. This approach reduces environmental impact while ensuring that modular homes remain durable — they can last 50 years or more.",
    data: "21.09.2024",
  },
  blog15: {
    title: "Building Permit and Modular House Passport",
    text: "Building a regular private house requires mandatory documentation. In the case of modular construction, this is unnecessary. However, if you decide to settle in one place for an extended period and install the house on a foundation, you can obtain documents to officially designate it as immovable property. The land on which the modular house is located must be leased or owned. Permits are not required even when utilities are connected to the site if you have project documents confirming that the modular house is a permanent structure. You can then contact the local architectural bureau. After that, the house will receive a construction passport, an inventory address, and the possibility of residence registration. This will secure your property rights and ensure full insurance coverage.",
    data: "21.09.2024",
  },
  blog16: {
    title: "Variety of Uses",
    text: "Modular homes are not always built for single-family housing. They can also serve as hotels, hostels, campsites, dormitories, cafes, or resorts.",
    data: "21.09.2024",
  },
  blog17: {
    title: "Modular House in Winter",
    text: "Modular homes are suitable for winter living, another significant advantage. In winter, modular homes are connected to autonomous heating or central networks. Most often, they use induction heaters or a boiler. With their primary gas heating, owners of modular homes will not notice any difference from a traditional private house with standard heating. They can also connect to the main power grid and enjoy cozy indoor conditions. Additionally, modern modules are designed with excellent thermal insulation. Infrared heating systems, integrated automation, and 'Smart Home' technologies can also be installed in modular homes.",
    data: "21.09.2024",
  },
  blog18: {
    title: "Conclusions",
    text: "So what are modular houses: a temporary solution in hopeless situations or a cozy family nest? In fact, everything depends on the quality, purposes of use, materials, location. Modern technologies make it possible to create functional and energy-efficient housing that is quite comfortable in a limited time. Choosing a modular home is a choice of modern technologies, comfort and ecology. Whether you need it as a permanent residence or a country retreat, this home offers many possibilities to create the perfect home that meets all your needs and desires.",
    data: "21.09.2024",
  },
};
export const houseEn = {
  timeUnits: {
    weeks: "weeks",
    months: "months",
  },
  housesFilling: {
    title: "Filling a modular house",
    includedPrice: "Included in the price",
    inside: "Inside",
    outside: "Outside",
    notIncludedPrice: "Not included in the price",
  },
  housePlan: {
    title: "Plan of the future house",
    characteristics: "characteristic",
    bedroom: "Bedroom",
    kitchenLiving: "Kitchen-living room",
    bathroom: "Kitchen-living room",
  },
  gallery: {
    title: "Gallery",
    facade: "Facade",
    interior: "Interior",
  },
  houseSimilar: {
    title: "Similar Projects",
    button: "More details",
  },
  question: "Popular questions",
  price: "Price",
  buildingArea: "Building area",
  usefulArea: "Useful Area",
  design: "Design",
  production: "Production",
  transportation: "Transportation",
  installation: "Installation",
  from: "from",
};
