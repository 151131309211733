import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import {
  blogUk,
  housesUk,
  houseUk,
  questionsAnswersUk,
} from "./data/translationUk";
import { blogEn, houseEn, questionsAnswersEn } from "./data/translationEN";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        nav: {
          logo: "Modern housing under the open sky",
          home: "Home",
          modularHouses: "Modular Houses",
          gallery: "Gallery",
          prices: "Prices",
          contactUs: "Contact Us",
          blog: "Blog",
          gonta: "Modular House",
        },
        hero: {
          title: "We collect comfort <br/> for 1 month",
          button: "Call back",
        },
        about: {
          title: "About us",
          mission_title: "Our Mission",
          consultation: "get a consultation",
          mission_text:
            "To make the process of building your ideal home as simple and enjoyable as possible.",
          quality_title: "Quality and Reliability",
          quality_text:
            "Our modular homes are built using the latest technologies and materials, ensuring their durability and eco-friendliness.",
          team_title: "Our Team is Always Ready to Help You",
          team_text:
            "We are confident that working with us will be a pleasant experience that will turn your dream of the perfect home into reality.",
          text1:
            "<span class='text-accent-500' >SkyHouse</span> is a professional and experienced team passionately dedicated to creating and delivering the best modular homes to you. With our years of experience and knowledge in the construction industry, we aim to provide you with high-quality and stylish housing solutions.",
          text2:
            "<span class='text-accent-500' >Our mission</span> is to make the process of building your ideal home as simple and enjoyable as possible. We believe that every family deserves a comfortable and cozy home, which is why we work hard to offer you the best solutions in modular construction. Homes built using the modular method are constructed very quickly, they are just as high-quality and reliable as those built using traditional methods, and in many ways, they even surpass them.",
          text3:
            "<span class='text-accent-500' >At SkyHouse, we are committed to quality and reliability.</span> Our modular homes are made using the latest technologies and materials, ensuring their durability and sustainability. Additionally, we carefully study your needs and desires to ensure that every project meets your unique requirements and quality standards.",
          text4:
            "<span class='text-accent-500' >Our team is always ready to assist you with any questions or consultations.</span> We are confident that working with us will be a pleasant experience, turning your dreams of the perfect home into reality.",
          signature: "CHOOSE SKYHOUSE - CHOOSE THE BEST FOR YOUR LIFE!",
        },
        processSteps: {
          step1: {
            title: "Introduction",
            text: "<span class='font-bold' > SkyHouse </span> is a professional and experienced team passionately dedicated to creating and delivering the best modular homes to you. With our years of experience and knowledge in the construction industry, we aim to provide you with high-quality and stylish housing solutions.",
          },
          step2: {
            title: "Design",
            text: "<span class='font-bold' > SkyHouse </span> is a professional and experienced team passionately dedicated to creating and delivering the best modular homes.",
          },
          step3: {
            title: "Contract",
            text: "<span class='font-bold' > SkyHouse </span> is a professional and experienced team passionately dedicated to creating and delivering the best modular homes to you. With our years of experience and knowledge in the construction industry, we aim to provide you with high-quality and stylish housing solutions.",
          },
          step4: {
            title: "Production",
            text: "<span class='font-bold' > SkyHouse </span> is a professional and experienced team passionately dedicated to creating and delivering the best modular homes to you. With our years of experience and knowledge in the construction industry, we aim to provide you with high-quality and stylish housing solutions.",
          },
          step5: {
            title: "Installation",
            text: "<span class='font-bold' > SkyHouse </span> is a professional and experienced team passionately dedicated to creating and delivering the best modular homes to you. With our years of experience and knowledge in the construction industry, we aim to provide you with high-quality and stylish housing solutions.",
          },
        },
        advantages: {
          title: "Why People Now Prefer Modular Homes",
          text: "Modular houses are an ideal choice that will allow not only to realize a dream, but also to create a wonderful house for business, where every corner will radiate coziness and inspiration.",
          items: {
            item1: { title: "Fast Construction" },
            item2: { title: "Mobility" },
            item3: { title: "Energy Efficiency" },
            item4: { title: "Wide Choice of Locations" },
            item5: { title: "Engineering in Modular Home" },
            item6: { title: "Year-Round Construction" },
            item7: { title: "Environmental Care" },
            item8: { title: "Easy Replanning" },
          },
          button: "Learn All Benefits",
        },
        questionsAnswers: questionsAnswersEn,
        sendingApplication: {
          title: "Questions remain",
          description: "Contact us:",
          name: "Name:",
          phone: "Phone:",
          button: "Send an application",
        },
        footer: {
          aboutCompany: "About Company",
          companyDescription:
            "The company 'SKY HOUSE' designs and manufactures frame-panel and modular houses at its own production facility in Lviv, Ukraine.",
          navigation: "Navigation",
          home: "Home",
          aboutUs: "About Us",
          contacts: "Contacts",
          blog: "Blog",
          pricing: "Pricing",
          gallery: "Gallery",
          contactInfo: "Contact Info",
          address: "Address",
          city: "Lviv",
          phone: "Phone",
          email: "Email",
          privacyPolicy: "Privacy Policy",
          copyright: "©2024 Sky House. All rights reserved.",
        },

        blog: blogEn,
        modal: {
          title: "Have Questions?",
          description: "Submit your request in a convenient way",
          namePlaceholder: "Enter name",
          phonePlaceholder: "Enter phone",
          submitButton: "Submit request",
        },
        house: houseEn,
        form: {
          formTitle: "Liked the house?",
          formDescription:
            "Have questions? Fill out the form and our manager will contact you shortly!",
          nameLabel: "Enter your name",
          namePlaceholder: "Name",
          phoneLabel: "Enter your phone number",
          phonePlaceholder: "Phone number",
          buttonLabel: "Contact me",
          privacyText:
            "By clicking the button you confirm that you have read and agree with",
          privacyPolicy: "Privacy Policy",
          loadingText: "Sending...",
        },
        notification: {
          success: "Email sent successfully!",
          error: "Failed to send email. Please try again.",
        },
      },
    },
    uk: {
      translation: {
        nav: {
          logo: "Сучасне житло під відкритим небом",
          home: "Головна",
          modularHouses: "МОДУЛЬНІ БУДИНКИ",
          gallery: "Галерея",
          prices: "Ціни",
          contactUs: "Зв'язатися з нами",
          gonta: "Каталог",
          blog: "Блог",
        },
        hero: {
          title: "Збираємо комфорт <br/> за 1 місяць",
          button: "Зворотній Дзвінок",
        },

        about: {
          title: "Про нас",
          consultation: "отримати консультацію",
          promoTitle: "ОБИРАЙТЕ SKYHOUSE  - ОБИРАЙТЕ НАЙКРАЩЕ ДЛЯ СВОГО ЖИТТЯ!",
          mission_title: "Наша місія",
          mission_text:
            "Зробити процес будівництва вашого ідеального будинку якомога простішим і приємним.",
          quality_title: "Якість та надійність",
          quality_text:
            "Наші модульні будинки виготовляються з використанням найсучасніших технологій та матеріалів, що гарантує їхню довговічність та екологічність.",
          team_title: "Наша команда завжди готова допомогти вам",
          team_text:
            "Ми переконані, що співпраця з нами стане для вас приємним досвідом, який перетворить ваші мрії про ідеальний будинок на реальність.",
          text1:
            "<span class='text-accent-500' >SkyHouse</span> – це професійна та досвідчена команда, яка пристрасно присвячена створенню та постачанню вам найкращих модульних будинків. З нашими роками досвіду і знаннями у галузі будівництва, ми прагнемо забезпечити вам якісні та стильні рішення для вашого житла.",
          text2:
            "<span class='text-accent-500' >Наша місія</span> - зробити процес будівництва вашого ідеального будинку якнайпростішим і приємним. Ми віримо у те, що кожна родина заслуговує на комфортне та затишне житло, і саме тому ми працюємо наполегливо, щоб забезпечити вам найкращі рішення у сфері модульного будівництва. Виготовлені модульним методом будинки зводяться дуже швидко, вони абсолютно не поступаються якістю і надійністю будівлям, побудованим за традиційними технологіями, а багато в чому навіть перевершують їх.",
          text3:
            "<span class='text-accent-500' >У SkyHouse ми віддані якості та надійності.</span> Наші модульні будинки виготовляються з використанням найсучасніших технологій та матеріалів, що гарантує їхню довговічність та екологічність. Крім того, ми ретельно вивчаємо ваші потреби та бажання, щоб кожен наш проєкт відповідав вашим унікальним вимогам та стандартам якості.",
          text4:
            "<span class='text-accent-500' >Наша команда завжди готова допомогти вам з будь-якими питаннями та консультаціями.</span> Ми переконані, що співпраця з нами стане для вас приємним досвідом, який перетворить ваші мрії про ідеальний будинок на реальність.",
          signature: "ОБИРАЙТЕ SKYHOUSE  - ОБИРАЙТЕ НАЙКРАЩЕ ДЛЯ СВОГО ЖИТТЯ!",
        },
        processSteps: {
          step1: {
            title: "Знайомство",
            text: "<span class='font-bold' > SkyHouse </span> – це професійна та досвідчена команда, яка пристрасно присвячена створенню та постачанню вам найкращих модульних будинків. З нашими роками досвіду і знанням у галузі будівництва, ми прагнемо забезпечити вам якісні та стильні рішення для вашого житла.",
          },
          step2: {
            title: "Проектування",
            text: "<span class='font-bold' > SkyHouse </span> – це професійна та досвідчена команда, яка пристрасно присвячена створенню та постачанню вам найкращих модульних будинків.",
          },
          step3: {
            title: "Договір",
            text: "<span class='font-bold' > SkyHouse </span> – це професійна та досвідчена команда, яка пристрасно присвячена створенню та постачанню вам найкращих модульних будинків. З нашими роками досвіду і знанням у галузі будівництва, ми прагнемо забезпечити вам якісні та стильні рішення для вашого житла.",
          },
          step4: {
            title: "Виробництво",
            text: "<span class='font-bold' > SkyHouse </span> – це професійна та досвідчена команда, яка пристрасно присвячена створенню та постачанню вам найкращих модульних будинків. З нашими роками досвіду і знанням у галузі будівництва, ми прагнемо забезпечити вам якісні та стильні рішення для вашого житла.",
          },
          step5: {
            title: "Встановлення",
            text: "<span class='font-bold' > SkyHouse </span> – це професійна та досвідчена команда, яка пристрасно присвячена створенню та постачанню вам найкращих модульних будинків. З нашими роками досвіду і знанням у галузі будівництва, ми прагнемо забезпечити вам якісні та стильні рішення для вашого житла.",
          },
        },
        advantages: {
          title: "Чому люди зараз надають перевагу модульним будинкам",
          text: "Модульні будинки – ідеальний вибір, який дозволить не лише здійснити мрію, але й створити чудовий будиночок для бізнесу, де кожен куточок випромінюватиме затишок і натхнення.",
          items: {
            item1: { title: "Швидке будівництво" },
            item2: { title: "Мобільність" },
            item3: { title: "Енерго - ефективність" },
            item4: { title: "Великий вибір місць розташування" },
            item5: { title: "Інженерні комунікації в модульному будинку" },
            item6: { title: "Цілорічне будівництво" },
            item7: { title: "Турбота про навколишнє середовище" },
            item8: { title: "Легке перепланування" },
          },
          button: "Дізнатись всі переваги",
        },
        questionsAnswers: questionsAnswersUk,
        sendingApplication: {
          title: "Залишились запитання",
          description: "Зв'яжіться з нами:",
          name: "Ім'я:",
          phone: "Телефон:",
          button: "Надіслати заявку",
        },
        footer: {
          aboutCompany: "Про компанію",
          companyDescription:
            "Компанія «SKY HOUSE» проєктує та виготовляє каркасно-панельні та модульні будинки на власному виробництві у Івано-Франківську, Україна.",
          navigation: "Навігація",
          home: "Головна",
          blog: "Блог",
          aboutUs: "Про нас",
          contacts: "Контакти",
          pricing: "Ціни",
          gallery: "Галерея",
          contactInfo: "Контакти",
          address: "Адреса",
          city: "м. Львів",
          phone: "Телефон",
          email: "Email",
          privacyPolicy: "Політика конфіденційності",
          copyright: "©2024 Sky House. Всі права захищено.",
        },
        blog: blogUk,
        modal: {
          title: "Залишились Запитання?",
          description: "Надішліть заявку у зручний спосіб",
          namePlaceholder: "введіть ім'я",
          phonePlaceholder: "введіть телефон",
          submitButton: "надіслати заявку",
        },
        houses: housesUk,
        house: houseUk,
        form: {
          formTitle: "Сподобався будинок?",
          formDescription:
            "Залишилися запитання? Заповніть форму і наш менеджер зв'яжеться з вами найближчим часом!",
          nameLabel: "Введіть ваше ім'я",
          namePlaceholder: "Ім'я",
          phoneLabel: "Введіть номер телефону",
          phonePlaceholder: "Номер телефону",
          buttonLabel: "Зв'яжіться зі мною",
          privacyText:
            "Натискаючи кнопку ви підтверджуєте, що ознайомилися і погодилися з",
          privacyPolicy: "Політикою конфіденційності",
          loadingText: "Надсилається...",
        },
        catalog: {
          title: "Каталог",
          options:
            "Тут ви знайдете найпопулярніші варіанти модульних будинків.",
          planning: "Подивитися планування",
          proj: "Не знайшли підходящий проект?",
          custom: "Розробимо для вас індивідуальний будинок",
          submit: "Залишити заявку",
        },
        notification: {
          success: "Лист успішно надіслано!",
          error: "Не вдалося надіслати листа. Будь ласка, спробуйте ще раз.",
        },
      },
    },
    pl: {
      translation: {
        form: {
          loadingText: "Wysyłanie...",
        },
        notification: {
          success: "Email został wysłany pomyślnie!",
          error: "Nie udało się wysłać emaila. Proszę spróbować ponownie.",
        },
      },
    },
  },
  lng: "uk",
  fallbackLng: "uk",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
