import emailjs from "emailjs-com";

import { toast } from "react-toastify";
export const parseArea = (area) => {
  return parseFloat(area.replace(/[^\d,.]/g, "").replace(",", "."));
};

export const sendEmail = (e, t, setLoading) => {
  e.preventDefault();

  emailjs
    .sendForm(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID_QUICKLY,
      e.target,
      process.env.REACT_APP_PUBLIC_KEY
    )
    .then(
      (result) => {
        toast.success(t("notification.success"));
        e.target.reset();
        setLoading(false);
      },
      (error) => {
        toast.error(t("notification.error"));
        setLoading(false);
      }
    );
};
